import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import Translate from '../../Translate/Translate';
import NewsItem from './NewsItem';
import ErrorBanner from '../../../components/404';
import Loader from '../../Loader/Loader';
import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/24/solid';
import './styles/index.scss';

const GET_POSTSCATEGORIES = gql`
  query PostsCategories($page: Int, $categorySlugs: [String]) {
    postCategories {
      id
      title
      slug
    }
    posts(page: $page, categorySlugs: $categorySlugs) {
      id
      title
      slug
      preview
      content
      cover {
        urls {
          W800
          W1000
        }
        cdn_url
      }
      categories {
        id
        title
        slug
      }
      createdAt
      author
      authorExtra
    }
  }
`;

type PostCategoriesItem = {
  id: string;
  title: string;
  slug: string;
};

type PostsItem = {
  id: string;
  title: string;
  slug: string;
  preview: string;
  content: string;
  cover: {
    cdn_url: string;
    urls: {
      W1000: string;
      W800: string;
    };
  };
  categories: {
    id: string;
    slug: string;
    title: string;
  }[];
  createdAt: string;
  author: string;
  authorExtra: string;
};

export default function NewsPage() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const [page, setPage] = useState(1);

  const urlParams = new URLSearchParams(search);
  const categoryStr: string = urlParams.get('category');
  const activeCategories = categoryStr ? categoryStr.split(',') : [];

  const { loading, error, data } = useQuery<{
    postCategories: PostCategoriesItem[];
    posts: PostsItem[];
  }>(GET_POSTSCATEGORIES, {
    variables: { page: page, categorySlugs: activeCategories },
  });

  if (loading) {
    return (
      <div className="flex justify-center m-10">
        <Loader />
      </div>
    );
  }

  if (error) {
    return <ErrorBanner titleKeyword="no-posts" />;
  }

  // const onLoadPosts = () => {
  //   setPage(page + 1);
  // };

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  const updateSearchParam = (slug) => {
    let newCategories = [];
    if (activeCategories.indexOf(slug) >= 0) {
      newCategories = activeCategories.filter((category) => category !== slug);
    } else {
      newCategories = [...activeCategories, slug];
    }

    setPage(1);

    navigate({
      search: `?category=${newCategories}`,
    });
  };

  const categorysMap = () => {
    const categorys = data.postCategories.map((cat, index) => {
      let className = 'category__link';

      if (activeCategories.includes(cat.slug)) {
        className += ' active';
      }

      return (
        <div
          key={index}
          onClick={() => updateSearchParam(cat.slug)}
          className={className}
        >
          {cat.title}
        </div>
      );
    });

    return <div className="page-news__hero__categorys">{categorys}</div>;
  };

  return (
    <div className="page-news">
      <div className="page-news__hero">
        <h1>
          <Translate component="footer" keyword="blogi-rademar" />
        </h1>
        {categorysMap()}
      </div>
      <div className="page-news__list">
        <div className="page-news__list__items">
          {data.posts.length < 1 ? (
            <div className="my-10 mx-2 paragraph">
              <Translate component="blog" keyword="no-older-posts" />
            </div>
          ) : null}
          {data.posts.map((item, index) => {
            return <NewsItem key={index} data={item} />;
          })}
        </div>
      </div>

      <div className="page-news__load">
        {page > 1 ? (
          <button onClick={previousPage} className="button mx-2">
            <ChevronLeftIcon className="mr-2 h-5 w-5" />
            <Translate component="blog" keyword="load-newer" />
          </button>
        ) : null}
        {data.posts.length < 8 ? null : (
          <button onClick={nextPage} className="button mx-2">
            <Translate component="blog" keyword="load-older" />
            <ChevronRightIcon className="ml-2 h-5 w-5" />
          </button>
        )}
      </div>
    </div>
  );
}
